import React from 'react';

function VideoComponent({ title, src, link }) {
  return (
    <div className="window w-[230px] mr-[10px]">
      <div className="title-bar">
        <div className="title-bar-text">{title}</div>
        <div className="title-bar-controls">
          <button aria-label="Minimize">_</button>
          <button aria-label="Maximize"></button>
          <button aria-label="Close"></button>
        </div>
      </div>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <div className="window-body">
            <video autoPlay loop muted className="w-full h-auto border-black">
              <source src={src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </a>
      ) : (
      
        <div className="window-body">
          <video autoPlay loop muted className="w-full h-[223px] border-black">
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </div>
  );
}

export default VideoComponent;
