// DepositPrompt.js
import React, { useState } from 'react';


function DepositPrompt({ onClose, onSubmit }) {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = () => {
    if (inputValue && !isNaN(inputValue)) {
      onSubmit(inputValue); // Call the submit function with the input amount
      setInputValue(''); // Clear input field after submission
      onClose(); // Close the prompt after submission
    } else {
      alert("Please enter a valid number.");
    }
  };

  return (
    <div className="window alert-box">
      <div className="title-bar">
        <div className="title-bar-text">Deposit</div>
        <div className="title-bar-controls">
          <button aria-label="Close" onClick={onClose}></button>
        </div>
      </div>
      <div className="window-body">
        <p>Enter the amount to deposit:</p>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter amount"
          className="input-field"
        />
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}

export default DepositPrompt;
