import React from 'react';

function ChatHeader({ userCount }) {
  return (
    <div className="title-bar flex items-center justify-between bg-blue-500 text-white p-2">
      <span className="title-bar-text">🤡 Chat</span>
      <div className="online-users">🎪 Circus Population: <span id="userCount">{userCount}</span></div>
    </div>
  );
}

export default ChatHeader;
