import React from 'react';
import './GeckoTerminalWidget.style.css';

function GeckoTerminalWidget() {
  return (
    <div className="window">
      <div className="title-bar">
        <div className="title-bar-text">Gecko Terminal</div>
        <div className="title-bar-controls">
          <button aria-label="Close">X</button>
        </div>  
      </div>
      <div className="window-body">
        <iframe 
          title="GeckoTerminal Embed" 
          src="https://www.geckoterminal.com/solana/pools/F113xeqwsusM2AAJuwNtQMAFYp4TnKMj5ZFWRAYgfWLe?embed=1&info=0&swaps=0" 
          frameBorder="0" 
          allow="clipboard-write" 
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default GeckoTerminalWidget;
