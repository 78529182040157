import React, { useState } from 'react';

function CreateGameModal({ 
  walletAddress, 
  socket, 
  setCreateGameModalVisible, 
  setAlertMessage, 
  setAlertVisible 
}) {
  const [gameCreation, setGameCreation] = useState({
    maxPlayers: '',
    minBet: ''
  });

  const handleGameCreationInput = (e) => {
    const { name, value } = e.target;
    setGameCreation(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCreateGameSubmit = () => {
    const { maxPlayers, minBet } = gameCreation;

    socket.emit('create_game', { 
      maxPlayers: parseInt(maxPlayers), 
      minBet: parseInt(minBet),
      walletAddress 
    });

    setCreateGameModalVisible(false);
    setGameCreation({ maxPlayers: '', minBet: '' });
  };

  return (
    <div className="window text-black"  style={{ 
      height: '190px',
      width: '270px', 
      margin: '10px', 
      position: 'absolute', 
      top: '38%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)', 
      zIndex: 1000 
    }}>
      <div className="title-bar">
        <div className="title-bar-text">Create Roulette Game</div>
        <div className="title-bar-controls">
          <button aria-label="Close" onClick={() => setCreateGameModalVisible(false)}></button>
        </div>
      </div>
      <div className="window-body">
        <div className="field-row-stacked" style={{ width: '250px' }}>
          <label htmlFor="maxPlayers">Max Players (2-30)</label>
          <input 
            id="maxPlayers"
            name="maxPlayers"
            type="number" 
            value={gameCreation.maxPlayers}
            onChange={handleGameCreationInput}
            min="2"
            max="30"
          />
        </div>
        <div className="field-row-stacked" style={{ width: '250px' }}>
          <label htmlFor="minBet">Minimum Bet (2000+ CLOWNS)</label>
          <input 
            id="minBet"
            name="minBet"
            type="number" 
            value={gameCreation.minBet}
            onChange={handleGameCreationInput}
            min="2000"
          />
        </div>
        <div className="field-row" style={{ justifyContent: 'flex-end', marginTop: '10px' }}>
          <button onClick={handleCreateGameSubmit}>Create Game</button>
        </div>
      </div>
    </div>
  );
}

export default CreateGameModal;