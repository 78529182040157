import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import ChatHeader from './chatHeader';
import MessageInput from './messageInput';
import MessageList from './messageList';
import Alert from '../alert';
import DepositPrompt from '../alert/DepositPrompt';
import WithdrawPrompt from '../alert/WithdrawPrompt';
import './ChatBox.style.css';

const socket = process.env.REACT_APP_USE_LOCAL_SOCKET === 'true'
  ? io('http://localhost:5001')
  : io('wss://clowns.chat', {
      path: '/socket.io/',
      transports: ['websocket'],
    });

function ChatBox() {
  const [messages, setMessages] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [userClownFace, setUserClownFace] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [balance, setBalance] = useState(0);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [depositVisible, setDepositVisible] = useState(false);
  const [withdrawVisible, setWithdrawVisible] = useState(false);

  const clownFaces = [
    "/images/clown_apple.png",
    "/images/clown_google_noto.png",
    "/images/clown_huawei.png",
    "/images/clown_pixels.png",
    "/images/clown_samsung_2.png",
    "/images/clown_whatsapp.png",
    "/images/clown_google_noto_wink.png",
    "/images/clown_microsoft.png",
    "/images/clown_samsung.png",
    "/images/clown_twitter.png"
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * clownFaces.length);
    setUserClownFace(clownFaces[randomIndex]);

    socket.on('chatHistory', (chatHistory) => {
      setMessages(chatHistory);
    });

    socket.on('message', (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    socket.on('user_count', (count) => {
      setUserCount(count);
    });

    socket.on('play_sound', (data) => {
      if (data.sound === 'gunshot') {
        playSound('/audio/gunshot.mp3');
      } else if (data.sound === 'survived') {
        playSound('/audio/survived.mp3');
      } else if (data.sound === 'won') {
        playSound('/audio/won.mp3');
      }
    });

    return () => {
      socket.off('chatHistory');
      socket.off('message');
      socket.off('user_count');
      socket.off('play_sound');
    };
  }, []);

  const playSound = (soundUrl) => {
    const audio = new Audio(soundUrl);
    audio.play();
  };

  const handleSendMessage = (message) => {
    const command = message.trim().toLowerCase();
  
    if (command === '/help') {
      socket.emit('request_help');
      socket.emit('message', {
        content: process.env.HELP_COMMAND_MESSAGE || 'Available commands: /help, /roulette {players} {bid}, /abort, /join, /pull',
        isSystem: true
      });
    } else if (command.startsWith('/roulette')) {
      const parts = command.split(' ').filter(Boolean);
      if (parts.length !== 3) {
        socket.emit('message', {
          content: process.env.INVALID_COMMAND_FORMAT_MESSAGE || '❌ Invalid command format! Use /roulette {players (max 6)} {bid (min 100)}.',
          isSystem: true
        });
        return;
      }
      const maxPlayers = parseInt(parts[1]);
      const minBet = parseInt(parts[2]);
  
      if (isNaN(maxPlayers) || isNaN(minBet)) {
        socket.emit('message', {
          content: process.env.INVALID_NUMBERS_MESSAGE || '❌ Invalid numbers. Please use valid integers for players and bid.',
          isSystem: true
        });
        return;
      }
  
      if (maxPlayers > 30 || maxPlayers < 2) {
        socket.emit('message', {
          content: process.env.INVALID_PLAYERS_MESSAGE || '❌ Invalid number of players. Max: 30, Min: 2',
          isSystem: true
        });
        return;
      }
  
      socket.emit('create_game', { maxPlayers, minBet });
    } else if (command === '/abort') {
      socket.emit('abort_game');
      socket.emit('message', {
        content: process.env.GAME_ABORT_MESSAGE || '🛑 The game was aborted!',
        isSystem: true
      });
    } else if (command.startsWith('/join')) {
      socket.emit('join_game', { username: userClownFace });
    } else if (command.startsWith('/pull')) {
      const parts = command.split(' ');
      const pulls = parts.length === 2 && !isNaN(parts[1]) ? parseInt(parts[1]) : 1;
      socket.emit('pull_trigger', { pulls });
    } else {
      socket.emit('message', { content: message, image: userClownFace });
    }
  };  
  

  const handleHonkMessage = () => {
    playSound('/audio/honk.mp3');
    socket.emit('message', { content: 'Honk 🎺', image: userClownFace });
  };

  const connectWallet = async () => {
    const provider = window.solana;

    if (provider && provider.isPhantom) {
      try {
        const resp = await provider.connect();
        const publicKey = resp.publicKey.toString();
        setWalletAddress(publicKey);

        socket.emit('connect_wallet', { walletAddress: publicKey });
        socket.emit('get_balance', { walletAddress: publicKey });

        socket.on('balance_update', (data) => {
          setBalance(data.balance);
        });

        setAlertMessage(`Connected to wallet: ${publicKey}`);
        setAlertVisible(true);
      } catch (err) {
        console.error("Wallet connection failed", err);
        setAlertMessage("Wallet connection failed. Please try again.");
        setAlertVisible(true);
      }
    } else {
      setAlertMessage("Phantom wallet not found! Please install Phantom.");
      setAlertVisible(true);
    }
  };

  const handleDeposit = () => {
    setDepositVisible(true);
  };

  const handleWithdraw = () => {
    setWithdrawVisible(true);
  };

  const handleCloseAlert = () => {
    setAlertVisible(false);
  };

  const handleCloseDeposit = () => {
    setDepositVisible(false);
  };

  const handleCloseWithdraw = () => {
    setWithdrawVisible(false);
  };

  return (
    <div className="window p-0 shadow-lg w-[100%] h-[80%] flex flex-col border border-black rounded-m bg-black bg-opacity-75">
      <ChatHeader userCount={userCount} />
      <MessageList messages={messages} />
      <MessageInput sendMessage={handleSendMessage} sendHonkMessage={handleHonkMessage} />
      <div className="wallet-actions flex items-center justify-between p-2 bg-transparent">
        <div className="flex items-center">
          <button
            className={`button ${walletAddress ? 'bg-green-500 text-white' : 'text-white'}`}
            onClick={connectWallet}
            disabled={!!walletAddress}
          >
            {walletAddress ? 'Connected!' : 'Connect Wallet'}
          </button>
          {walletAddress && (
            <div className="flex items-center ml-2">
              <div className="text-white font-bold">Balance: {balance} CLOWNS</div>
              <button className="button text-white ml-2" onClick={handleDeposit}>Deposit</button>
              <button className="button text-white ml-2" onClick={handleWithdraw}>Withdraw</button>
            </div>
          )}
        </div>
      </div>
      {alertVisible && <Alert message={alertMessage} onClose={handleCloseAlert} />}
      {depositVisible && <DepositPrompt onClose={handleCloseDeposit} onSubmit={(amount) => {
          if (amount && !isNaN(amount)) socket.emit('deposit', { walletAddress, amount });
        }}
      />}
      {withdrawVisible && <WithdrawPrompt onClose={handleCloseWithdraw} onSubmit={(amount) => {
          if (amount && !isNaN(amount)) socket.emit('withdraw', { walletAddress, amount });
        }}
      />}
    </div>
  );
}

export default ChatBox;
