import React from 'react';
import './Alert.style.css'; // Ensure you have this style file for Windows 98 look

function Alert({ message, onClose }) {
  return (
    <div className="window alert-box">
      <div className="title-bar">
        <div className="title-bar-text">Alert</div>
        <div className="title-bar-controls">
          <button aria-label="Close" onClick={onClose}></button>
        </div>
      </div>
      <div className="window-body">
        <p>{message}</p>
        <button onClick={onClose}>OK</button>
      </div>
    </div>
  );
}

export default Alert;
