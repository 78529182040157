import React from 'react';
import VideoBar from './components/VideoBar';
import VideoComponent from './components/VideoComponent';
import ChatBox from './components/chatBox';
import WebampContainer from './components/WebampContainer';
import GeckoTerminalWidget from './components/GeckoTerminalWidget';
import StartBar from './components/startBar';
import VideoPromptComponent from './components/TutorialVideoPrompt/TutorialVideoPrompt';
import '98.css';  
import './App.css';

function App() {
  return (
    <>
      {/* Webamp Container - Rendered at root level */}

      
      <div className="relative justify-center overflow-hidden h-screen w-screen">
        {/* Background Video */}
        <div className="absolute inset-0 z-0 overflow-hidden">
          <video autoPlay loop muted className="w-full h-full object-cover">
            <source src="/videos/pixels.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 bg-gray-100 bg-opacity-50"></div>
        </div>

        {/* Video Bars at the Top */}
        <VideoBar />

        {/* Main Layout */}
        <div className="relative flex items-start justify-center w-screen h-screen">
          <div className="flex flex-row justify-center w-[99%] h-full overflow-hidden">
            {/* Left Column for Images/Videos */}
            <div className="flex flex-col justify-start w-[20%] h-[30%]" >  
              <VideoComponent title="This is you right now..." src="/videos/sam-troll_2.mp4" />
              <VideoComponent title="Buy on Jup!" src="/videos/jup.mp4" link="https://jup.ag/swap/SOL-CGvA4rngdetvEVDsuyRUWsYAxU434cqfCRRLFrgJ9gJT" />
              <VideoPromptComponent 
                  thumbnailSrc="/images/game6.png" 
                  videoSrc="/videos/tutorial2.mov" 
                  title="Clown Roulette Video Tutorial... (Click me!)" 
/>              </div>
            {/* Middle Column for Chat Box */}
            <ChatBox />

            {/* Right Column for Gecko Terminal */}
            <div className="flex flex-col justify-start w-1/4 h-[85%] z-50 ml-1">
              <WebampContainer />
              <GeckoTerminalWidget />
            </div>
          </div>
        </div>

        {/* Start Bar */}
        <StartBar className="z-60"/>
      </div>
    </>
  );
}

export default App;