import React, { useState } from 'react';

function MessageInput({ sendMessage, sendHonkMessage }) {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (message.trim()) {
      sendMessage(message);
      setMessage('');
    }
  };

  return (
    <div className="flex items-center border-t border-gray-210 p-2 bg-transparent">
      <input
        type="text"
        placeholder="Type your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSend()} // Send on Enter key press
        className="w-11/12 h-8 p-2 border border-black bg-white mr-2"
      />
      <button className="button text-red-400" onClick={sendHonkMessage}>Honk 🎺</button>
      <button className="button text-white" onClick={handleSend}>Send</button>
    </div>
  );
}

export default MessageInput;
