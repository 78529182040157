import React, { useState } from 'react';
import './StartBar.style.css';

function CopyAddress() {
  const [visible, setVisible] = useState(false);

  const handleCopyAddress = (e) => {
    e.preventDefault();
    const address = "CGvA4rngdetvEVDsuyRUWsYAxU434cqfCRRLFrgJ9gJT"; // Your contract address here
    navigator.clipboard.writeText(address).then(() => {
      setVisible(true); // Show the helpful bar
      const notificationSound = new Audio('static/audio/Clown_Horn Sound_Effect.mp3');
      notificationSound.currentTime = 0; // Reset sound to start
      notificationSound.play().catch(error => {
        console.error("Failed to play sound:", error);
      });
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const closeHelpfulBar = () => {
    setVisible(false);
  };

  return (
    <>
      <a href="#" className="flex items-center w-full" onClick={handleCopyAddress}>
        <img src="/images/icons/address.png" className="mr-3 h-8 w-8" alt="Copy Icon" />
        Contract Address
      </a>

      {/* Helpful Bar for Copy Address Confirmation */}
      {visible && (
        <div className="window" style={{ 
          width: '350px', 
          position: 'fixed', 
          top: '50%', 
          left: '50%',
          height: '100px',
          transform: 'translate(-50%, -50%)', 
          zIndex: 100 }}>
          <div className="title-bar">
            <div className="title-bar-text">Address Copied!</div>
            <div className="title-bar-controls">
              <button aria-label="Close" onClick={closeHelpfulBar}></button>
            </div>
          </div>
          <div className="window-body">
            <p>The contract address has been copied to your clipboard.</p>
          </div>
        </div>
      )}
    </>
  );
}

export default CopyAddress;
