import React, { useRef, useEffect } from 'react';

function MessagesList({ messages, username }) {
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-2 bg-transparent mb-2">
      {messages.map((msg, index) => (
        <div key={index} className="chat-message mb-2">
          <div className="flex items-center">
            {/* Only display user image and username for non-system messages */}
            {!msg.isSystem && (
              <div className="flex items-center mr-2">
                <img src={msg.user_image} alt="User Avatar" className="w-8 h-8 rounded-full mr-1" />
                <span className="font-bold text-l text-white">
                  {msg.username || username || 'Anonymous'}
                </span>
              </div>
            )}
            <div className="message-content">
              <div className="message-text" dangerouslySetInnerHTML={{ __html: msg.content }} />
              {!msg.isSystem && (
                <div className="timestamp text-gray-400 text-sm">
                  {msg.timestamp ? new Date(msg.timestamp).toLocaleString() : 'No timestamp'}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MessagesList;
