import React, { useState } from 'react';
import CopyAddress from './CopyAddress'; // Import the new component
import './StartBar.style.css';

function StartBar() {
  const [menuVisible, setMenuVisible] = useState(false);

  // Toggle the start menu visibility
  const toggleStartMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <div className="start-bar z-60">
        {/* Start button with click functionality */}
        <button className="button start-button" onClick={toggleStartMenu}>
          <img src="/images/icons/windows-4.png" alt="Start Button" className="mr-2" />
          Start
        </button>

        {/* Clock */}
        <div className="clock">
          {new Date().toLocaleTimeString()}
        </div>
      </div>

      {menuVisible && (
        <div id="startMenu" className="start-menu z-50">
          <ul>
            <li className="menu-item">
              <CopyAddress /> {/* Use the CopyAddress component here */}
            </li>
            <li className="menu-item">
              <a href="https://solscan.io/token/CGvA4rngdetvEVDsuyRUWsYAxU434cqfCRRLFrgJ9gJT#holders" target="_blank" className="flex items-center w-full">
                <img src="/images/icons/clown_apple.png" className="mr-3 h-8 w-8" alt="Clown Explorer" />
                Clown Explorer
              </a>
            </li>
            <li className="menu-item">
              <a href="https://dexscreener.com/solana/f113xeqwsusm2aajuwntqmafyp4tnkmj5zfwraygfwle" target="_blank" className="flex items-center w-full">
                <img src="/images/icons/dex_screener.png" className="mr-3 h-8 w-8" alt="Dex Screener" />
                Dex Screener
              </a>
            </li>
            <div className="separator"></div>
            <li className="menu-item">
              <a href="https://x.com/clown_the_emoji" target="_blank" className="flex items-center w-full">
                <img src="/images/icons/x.png" className="mr-3 h-8 w-8" alt="X" />
                X
              </a>
            </li>
            <li className="menu-item">
              <a href="https://t.me/clown_the_emoji" target="_blank" className="flex items-center w-full">
                <img src="/images/icons/telegram.png" className="mr-3 h-8 w-8" alt="Telegram" />
                Telegram
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}

export default StartBar;
