import React, { useState } from 'react';
import './TutorialVideoPrompt.style.css';

function VideoPrompt({ onClose, videoSrc, title }) {
  return (
    <div className="window fixed top-[15%] left-1/2 transform -translate-x-1/2 z-[100] w-[390px]">
      <div className="title-bar">
        <div className="title-bar-text">{title}</div>
        <div className="title-bar-controls">
          <button aria-label="Close" onClick={onClose}></button>
        </div>
      </div>
      <div className="window-body">
        <div className="video-container w-full h-[800px] flex items-center justify-center">
          <video 
            controls 
            autoPlay 
            className="max-w-full max-h-full object-contain"
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}

function VideoPromptComponent({ thumbnailSrc, videoSrc, title }) {
  const [isPromptOpen, setIsPromptOpen] = useState(false);

  return (
    <>
      <div className="window w-[290px] mr-[5px]">
        <div className="title-bar">
          <div className="title-bar-text">{title}</div>
          <div className="title-bar-controls">
            <button aria-label="Minimize">_</button>
            <button aria-label="Maximize"></button>
            <button aria-label="Close"></button>
          </div>
        </div>
        <div 
          className="window-body cursor-pointer" 
          onClick={() => setIsPromptOpen(true)}
        >
          <img 
            src={thumbnailSrc} 
            alt={title} 
            className="w-full h-auto object-contain" 
          />
        </div>
      </div>

      {isPromptOpen && (
        <VideoPrompt 
          onClose={() => setIsPromptOpen(false)}
          videoSrc={videoSrc}
          title={title}
        />
      )}
    </>
  );
}

export default VideoPromptComponent;