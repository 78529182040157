// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.window {
    width: 350px;
    height: 700px; /* Or a specific value like 600px */
  }
  
  .window-body {
    display: flex;
    flex-direction: column;
    height: 93%;
  }
  
  iframe {
    width: 100%;
    height: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/GeckoTerminalWidget/GeckoTerminalWidget.style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa,EAAE,mCAAmC;EACpD;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".window {\n    width: 350px;\n    height: 700px; /* Or a specific value like 600px */\n  }\n  \n  .window-body {\n    display: flex;\n    flex-direction: column;\n    height: 93%;\n  }\n  \n  iframe {\n    width: 100%;\n    height: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
