// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  /* ChatBox.style.css */
.chat-message {
    font-family: 'Courier New', monospace;  /* Example font */
    font-size: 16px;                        /* Example size */
    color: #ffffff;                         /* Example text color */
    line-height: 1.4;                       /* Optional: adjust spacing between lines */
  }
  .system-message {
    color: yellow; /* Or any other color you prefer for system messages */
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/chatBox/ChatBox.style.css"],"names":[],"mappings":";EACE,sBAAsB;AACxB;IACI,qCAAqC,GAAG,iBAAiB;IACzD,eAAe,yBAAyB,iBAAiB;IACzD,cAAc,0BAA0B,uBAAuB;IAC/D,gBAAgB,wBAAwB,2CAA2C;EACrF;EACA;IACE,aAAa,EAAE,sDAAsD;IACrE,iBAAiB;EACnB","sourcesContent":["\n  /* ChatBox.style.css */\n.chat-message {\n    font-family: 'Courier New', monospace;  /* Example font */\n    font-size: 16px;                        /* Example size */\n    color: #ffffff;                         /* Example text color */\n    line-height: 1.4;                       /* Optional: adjust spacing between lines */\n  }\n  .system-message {\n    color: yellow; /* Or any other color you prefer for system messages */\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
