import React from 'react';

function VideoBar() {
  return (
    <>
      {/* First Video Ad */}
      <div className="relative mx-auto flex justify-center space-x-6 h-20 overflow-hidden z-50 mt-3"> {/* Added mt-5 for margin-top */}
        {/* First Video Ad */}
          <video autoPlay loop muted className="w-[210px] h-auto" style={{ maxHeight: '100%' }}>
            <source src="/videos/suibot.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

        {/* Second Video Ad */}
        <a href="https://ape.pro/" target="_blank" rel="noopener noreferrer">
          <video autoPlay loop muted className="w-[210px] h-auto" style={{ maxHeight: '100%' }}>
            <source src="/videos/ape.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </a>

        {/* Third Image Ad */}
        <a href="https://dominance.market/" target="_blank" rel="noopener noreferrer">
          <img src="/images/marketdominance.png" alt="Market Dominance Ad" className="w-[210px] h-auto" style={{ maxHeight: '100%' }} />
        </a>
      </div>
    </>
  );
}

export default VideoBar;
