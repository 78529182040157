import React from 'react';
import VideoBar from './components/VideoBar';
import VideoComponent from './components/VideoComponent';
import ChatBox from './components/chatBox';
import WebampContainer from './components/WebampContainer';
import GeckoTerminalWidget from './components/GeckoTerminalWidget';
import StartBar from './components/startBar';
import '98.css';  
import './App.css';

function App() {
  return (
    <div className="relative justify-center overflow-hidden h-screen w-screen">

      {/* Background Video */}
      <div className="absolute inset-0 z-0">
        <video autoPlay loop muted className="w-full h-full object-cover">
          <source src="/videos/pixels.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-gray-100 bg-opacity-50"></div>
      </div>

      {/* Video Bars at the Top */}
      <VideoBar />

      {/* Main Layout */}
      <div className="relative flex items-start justify-center w-screen h-screen z-50"> {/* Added margin-top here */}
        <div className="flex flex-row justify-center w-[85%] h-full">

          {/* Left Column for Images/Videos */}
          <div className="flex flex-col justify-start w-1/4 h-[30%]">  
            <VideoComponent title="You right now..." src="/videos/sam-troll_2.mp4" />
            <VideoComponent title="Buy on Jup!" src="/videos/jup.mp4" link="https://jup.ag/swap/SOL-CGvA4rngdetvEVDsuyRUWsYAxU434cqfCRRLFrgJ9gJT" />
            <VideoComponent title="Clown, It's Explosive" src="/videos/mushroom-cloud.mp4" />
          </div>

          {/* Middle Column for Chat Box */}
          <ChatBox />

          {/* Right Column for Webamp and Gecko Terminal */}
          <div className="flex flex-col justify-start w-1/4 h-[80%] z-50 -mr-20">
            <WebampContainer />
            <GeckoTerminalWidget />
          </div>

        </div>
      </div>

      {/* Start Bar */}
      <StartBar className="z-60"/>
    </div>
  );
}

export default App;
