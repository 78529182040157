import React, { useEffect } from 'react';
import Webamp from 'webamp';

function WebampContainer() {
  useEffect(() => {
    // Check if Webamp is supported in the browser
    if (!window.webampInstance) {
      if (!Webamp.browserIsSupported()) {
        alert("Oh no! Webamp does not work!");
        throw new Error("What's the point of anything?");
      }

      // Initialize Webamp with the specified tracks and options
      const webamp = new Webamp({
        initialTracks: [
          {
            metaData: {
              artist: "Crazy 2",
              title: "Crazy tile",
            },
            url: "/static/audio/1.mp3", // Path to your local MP3 file
          },
          {
            metaData: {
              artist: "Haddaway",
              title: "What is love?",
            },
            url: "/static/audio/2.mp3", // Path to your local MP3 file
          },
          {
            metaData: {
              artist: "Eiffel 65",
              title: "Blue",
            },
            url: "/static/audio/3.mp3", // Path to your local MP3 file
          },
          {
            metaData: {
              artist: "Mike Emilio",
              title: "Clown",
            },
            url: "/static/audio/4.mp3", // Path to your local MP3 file
          },
        ],
        options: {
          initialVolume: 0, // Start with volume at 0 (muted)
        },
        windowLayout: {
          main: {
            position: { top: 0, left: 0 },
            shadeMode: false,
            closed: false,
          },
          equalizer: {
            position: { top: 120, left: 0 },
            shadeMode: false,
            closed: true, // Start with the equalizer closed
          },
          playlist: {
            position: { top: 110, left: 0 },
            shadeMode: false,
            size: { extraHeight: 0, extraWidth: 0 },
            closed: false,
          },
        },
      });

      // Save the instance globally to avoid multiple initializations
      window.webampInstance = webamp;

      // Render Webamp inside the 'webampContainer' div
      webamp.renderWhenReady(document.getElementById("webampContainer"))
        .then(() => {
          console.log('Webamp successfully rendered!');

          // Unmute after a short delay and start playing
          setTimeout(() => {
            webamp.store.dispatch({
              type: "SET_VOLUME",
              volume: 70, // Set volume to 70%
            });
            webamp.play(); // Autoplay after rendering
          }, 2000); // Delay of 2 seconds
        })
        .catch((error) => {
          console.error('Error rendering Webamp:', error);
        });
    }

    // Cleanup function to dispose of Webamp when the component unmounts
    return () => {
      if (window.webampInstance) {
        window.webampInstance.dispose();
        window.webampInstance = null;
      }
    };
  }, []);

  return <div id="webampContainer" style={{ width: '210px', height: '300px', marginLeft: '62px' }}></div>;
}

export default WebampContainer;
