import React, { useEffect, useRef, useState } from 'react';
import Webamp from 'webamp';

function WebampContainer() {
  const webampRef = useRef(null);
  const containerRef = useRef(null);
  const [isWebampReady, setIsWebampReady] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const initWebamp = async () => {
      if (webampRef.current || !containerRef.current || !isMounted) {
        return;
      }

      if (!Webamp.browserIsSupported()) {
        console.error("Webamp is not supported in this browser");
        return;
      }

      try {
        const existingWebamp = document.getElementById('webamp');
        if (existingWebamp) {
          existingWebamp.remove();
        }

        // Calculate position for right column
        const rightColumn = containerRef.current.getBoundingClientRect();
        const rightOffset = rightColumn.left + 20; // Adjust this value to move left/right
        const topOffset = rightColumn.top + 20; // Adjust this value to move up/down

        const webamp = new Webamp({
          initialTracks: [
            {
              metaData: { artist: "Crazy 2", title: "Crazy tile" },
              url: "/audio/1.mp3",
            },
            {
              metaData: { artist: "Haddaway", title: "What is love?" },
              url: "/audio/2.mp3",
            },
            {
              metaData: { artist: "Eiffel 65", title: "Blue" },
              url: "/audio/3.mp3",
            },
            {
              metaData: { artist: "Mike Emilio", title: "Clown" },
              url: "/audio/4.mp3",
            },
          ],
          initialWindowLayout: [
            {
              type: 'main',
              position: { x: rightOffset, y: topOffset },
            },
            {
              type: 'playlist',
              position: { x: rightOffset, y: topOffset + 116 },
            },
            {
              type: 'equalizer',
              position: { x: rightOffset, y: topOffset + 232 },
              closed: true, // Keep the equalizer closed
            },
          ],
          __customPresets: {
            hideAbout: true,
            noMarquee: true,
          },
        });

        if (isMounted) {
          webampRef.current = webamp;

          await webamp.renderWhenReady(containerRef.current);

          if (isMounted) {
            setIsWebampReady(true);
            console.log('Webamp successfully rendered!');

            const webampElement = document.getElementById('webamp');
            if (webampElement) {
              webampElement.style.zIndex = '9999';
            }
          }
        }
      } catch (error) {
        console.error('Error initializing Webamp:', error);
      }
    };

    initWebamp();

    return () => {
      isMounted = false;
      if (webampRef.current) {
        try {
          webampRef.current.dispose();
        } catch (error) {
          console.error('Error disposing Webamp:', error);
        }
        webampRef.current = null;
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: '70%',
        pointerEvents: 'none',
        zIndex: 9999,
      }}
    />
  );
}

export default WebampContainer;
