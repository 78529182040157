import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Connection, PublicKey, Transaction, Keypair } from '@solana/web3.js';
import { createTransferCheckedInstruction, getAssociatedTokenAddress } from '@solana/spl-token';

// Local component imports
import ChatHeader from './chatHeader';
import MessageInput from './messageInput';
import MessageList from './messageList';
import Alert from '../alert';
import DepositPrompt from '../alert/DepositPrompt';
import WithdrawPrompt from '../alert/WithdrawPrompt';
import CreateGameModal from './CreateGameModal';

// Utility imports
import { getTokenDecimals } from '../utils/tokenUtils';

import './ChatBox.style.css';

// Sleep utility function
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

window.Buffer = window.Buffer || require("buffer").Buffer;

console.log("Environment check:", {
  REACT_APP_CENTRAL_WALLET: process.env.REACT_APP_CENTRAL_WALLET,
  REACT_APP_SOLANA_RPC_URL: process.env.REACT_APP_SOLANA_RPC_URL,
  REACT_APP_MINT_ADDRESS: process.env.REACT_APP_MINT_ADDRESS,
});

const socket = process.env.REACT_APP_USE_LOCAL_SOCKET === 'true'
  ? io('http://localhost:5001')
  : io('wss://clowns.chat', {
      path: '/socket.io/',
      transports: ['websocket'],
    });
    
    function ChatBox() {
      const [messages, setMessages] = useState([]);
      const [userCount, setUserCount] = useState(0);
      const [userClownFace, setUserClownFace] = useState('');
      const [walletAddress, setWalletAddress] = useState('');
      const [balance, setBalance] = useState(0);
      const [alertVisible, setAlertVisible] = useState(false);
      const [alertMessage, setAlertMessage] = useState('');
      const [depositVisible, setDepositVisible] = useState(false);
      const [withdrawVisible, setWithdrawVisible] = useState(false);
      const [clownName, setClownName] = useState('');
      const [username, setUsername] = useState('');
      const [gameDetails, setGameDetails] = useState(null);
      const [createGameModalVisible, setCreateGameModalVisible] = useState(false);

      const clownFaces = [
        "/images/clown_apple.png",
        "/images/clown_google_noto.png",
        "/images/clown_huawei.png",
        "/images/clown_pixels.png",
        "/images/clown_samsung_2.png",
        "/images/clown_whatsapp.png",
        "/images/clown_google_noto_wink.png",
        "/images/clown_microsoft.png",
        "/images/clown_samsung.png",
        "/images/clown_twitter.png"
      ];

      useEffect(() => {
        if (walletAddress) {
          fetchBalance(walletAddress, setBalance);
        }
      }, [walletAddress]);  

      useEffect(() => {
        // Listen for game updates
        socket.on('game_update', (updateData) => {
          if (updateData.type === 'game_info') {
            setGameDetails(updateData.gameInfo);
          }
        });
      
        // Clean up listener
        return () => {
          socket.off('game_update');
        };
      }, [socket]);      
    
      useEffect(() => {
        // Clear existing messages when component mounts
        setMessages([]);
    
        // Set random clown face
        const randomIndex = Math.floor(Math.random() * clownFaces.length);
        setUserClownFace(clownFaces[randomIndex]);
    
        // Set up socket listeners
        const setupSocketListeners = () => {
          // Remove any existing listeners first
          socket.off('chatHistory');
          socket.off('message');
          socket.off('user_count');
          socket.off('play_sound');
          socket.off('balance_update');
          
          // Handle chat history
          socket.once('chatHistory', (chatHistory) => {
            setMessages(chatHistory);
          });
    
          // Handle new messages
          socket.on('message', (data) => {
            setMessages((prevMessages) => [...prevMessages, data]);
          });
    
          // Handle user count
          socket.on('user_count', (count) => {
            setUserCount(count);
          });
    
          // Handle balance updates
          socket.on('balance_update', (data) => {
            setBalance(data.balance);
          });
    
          // Handle sounds
          socket.on('play_sound', (data) => {
            if (data.sound === 'gunshot') {
              playSound('/audio/gunshot.mp3');
            } else if (data.sound === 'survived') {
              playSound('/audio/survived.mp3');
            } else if (data.sound === 'won') {
              playSound('/audio/won.mp3');
            } else if (data.sound === 'empty') {
              playSound('/audio/empty.mp3')
            } else if (data.sound === 'lost') {
              playSound('/audio/lost.wav')
            } else if (data.sound === 'cocking') {
              playSound('/audio/cocking.mp3')
            }
          });
    
          // Request chat history from server
          socket.emit('requestChatHistory');
        };
    
        // Set up listeners
        setupSocketListeners();
    
        // Cleanup function
        return () => {
          socket.off('chatHistory');
          socket.off('message');
          socket.off('user_count');
          socket.off('play_sound');
          socket.off('balance_update');
        };
      }, []); 
    
      // Fetch the balance from the backend
      const fetchBalance = (walletAddress, setBalance) => {
        // Remove any existing balance response listener
        socket.off('balanceResponse');
        
        // Emit the 'getUserBalance' event to the backend
        socket.emit('getUserBalance', { walletAddress });
      
        // Listen for the response from the backend
        socket.on('balanceResponse', (data) => {
          if (data.error) {
            console.error('Error fetching balance:', data.error);
          } else {
            console.log(data)
            setBalance(data.balance); // Update balance state
            setUsername(data.username);
          }
        });
      };
    
      const playSound = (soundUrl) => {
        // Check if the audio can be played automatically
        if (typeof Audio !== 'undefined') {
          const audio = new Audio(soundUrl);
          
          // Attempt to play, but catch potential autoplay restrictions
          audio.play().catch(error => {
            console.warn('Autoplay was prevented:', error);
            // Optional: Add a way to play sound on user interaction
            document.addEventListener('click', () => {
              audio.play();
            }, { once: true });
          });
        }
      };
      
      const handleSendMessage = (message) => {
        const command = message.trim().toLowerCase();
        // Get wallet address from state or localStorage
        const walletAddress = localStorage.getItem('walletAddress')

        if (message.length > 250) {
          setAlertMessage("Message too long. Maximum 250 characters.");
          setAlertVisible(true);
          return;
        }
      
        if (command === '/help') {
          socket.emit('request_help');
          socket.emit('message', {
            content: '',
            isSystem: true
          });
        } else if (command.startsWith('/roulette')) {
          const parts = command.split(' ').filter(Boolean);
          if (parts.length !== 3) {
            socket.emit('message', {
              content: process.env.INVALID_COMMAND_FORMAT_MESSAGE || '❌ Invalid command format! Use /roulette {players (max 30)} {bid (min 2000)}.',
              isSystem: true
            });
            return;
          }
          const maxPlayers = parseInt(parts[1]);
          const minBet = parseInt(parts[2]);
      
          if (isNaN(maxPlayers) || isNaN(minBet)) {
            socket.emit('message', {
              content: process.env.INVALID_NUMBERS_MESSAGE || '❌ Invalid numbers. Please use valid integers for players and bid.',
              isSystem: true
            });
            return;
          }
      
          if (maxPlayers > 30 || maxPlayers < 2) {
            socket.emit('message', {
              content: process.env.INVALID_PLAYERS_MESSAGE || '❌ Invalid number of players. Max: 30, Min: 2',
              isSystem: true
            });
            return;
          }
      
          socket.emit('create_game', { 
            maxPlayers, 
            minBet,
            walletAddress 
          });
        } else if (command === '/abort') {
          socket.emit('abort_game', { walletAddress });
          
        } else if (command.startsWith('/join')) {
          const parts = command.split(' ').filter(Boolean);
          
          if (parts.length > 2) {
            socket.emit('message', {
              content: '❌ Invalid command format! Use /join (optional: bet)',
              isSystem: true
            });
            return;
          }

          else if (command.startsWith('/start')) {
            socket.emit('start_game');
            socket.emit('message', {
              content: process.env.GAME_START_MESSAGE || '🎲 Manually starting the game...',
              isSystem: true
            });
          }
      
          // If no bet is specified, don't send a bet parameter
          if (parts.length === 1) {
            socket.emit('join_game', { 
              walletAddress
            });
            return;
          }
      
          // If a bet is specified, validate and send it
          const bet = parseInt(parts[1]);
          if (isNaN(bet)) {
            socket.emit('message', {
              content: '❌ Invalid bet amount. Please use a valid number.',
              isSystem: true
            });
            return;
          }
      
          socket.emit('join_game', { 
            walletAddress,
            bet
          });
        } else if (command.startsWith('/pull')) {
          const parts = command.split(' ');
          const pulls = parts.length === 2 && !isNaN(parts[1]) ? parseInt(parts[1]) : 1;
          socket.emit('pull_trigger', { 
            pulls,
            walletAddress 
          });

        } else if (command === '/pass') {
          socket.emit('pass_turn', { walletAddress });
          
        } else {
          socket.emit('message', { 
            content: message, 
            image: userClownFace,
            walletAddress,
            username
          });
        }
      }
      
  const handleHonkMessage = () => {
    playSound('/audio/honk.mp3');
    socket.emit('message', { content: 'Honk 🎺', image: userClownFace, username });
  };

  const connectWallet = async () => {
    const provider = window.solana;

    if (provider && provider.isPhantom) {
      try {
        const resp = await provider.connect();
        const publicKey = resp.publicKey.toString();
        
        // Store wallet address in local storage
        localStorage.setItem('walletAddress', publicKey);
        setWalletAddress(publicKey);

        // Initialize user and get balance
        socket.emit('connect_wallet', { 
          walletAddress: publicKey,
          username: userClownFace // Include username for user creation if needed
        });
        // Request balance update
        socket.emit('get_balance', { walletAddress: publicKey });
        socket.on('wallet_connected', (data) => {
          setUsername(data.username);
        });

        socket.on('balance_update', (data) => {
          setBalance(data.balance);
        });

        setAlertMessage(`Connected to wallet: ${publicKey}`);
        setAlertVisible(true);
      } catch (err) {
        console.error("Wallet connection failed", err);
        setAlertMessage("Wallet connection failed. Please try again.");
        setAlertVisible(true);
      }
    } else {
      setAlertMessage("Phantom wallet not found! Please install Phantom.");
      setAlertVisible(true);
    }
  };

  useEffect(() => {
    const storedWalletAddress = localStorage.getItem('walletAddress');
    if (storedWalletAddress) {
      setWalletAddress(storedWalletAddress);
  
      // Optionally, emit events to sync balance
      socket.emit('connect_wallet', { walletAddress: storedWalletAddress });
      socket.emit('get_balance', { walletAddress: storedWalletAddress });
  
      socket.on('balance_update', (data) => {
        setBalance(data.balance);
      });
    }
  }, []);
  
  // Set up socket listener outside of the handleDeposit function to avoid adding multiple listeners
  useEffect(() => {
    socket.on('deposit_status', (response) => {
      if (response.success) {
        console.log("Deposit successful:", response);
        setBalance(response.balance);  // Update balance with the value from backend
        setAlertMessage(`Successfully deposited ${response.amount} CLOWN tokens.`);
      } else {
        console.error("Deposit failed:", response.message);
        setAlertMessage(response.message || "Deposit failed. Please try again.");
      }
      setAlertVisible(true);
    });
  
    return () => {
      socket.off('deposit_status'); // Clean up the listener on unmount
    };
  }, []);  

// Adjusted polling-based confirmation function
async function confirmTransactionWithHttpPolling(connection, signature, timeout = 60000) {  // Extended timeout
  const startTime = Date.now();
  let status = null;
  
  while (Date.now() - startTime < timeout) {
    try {
      // Fetch the transaction's status with history search enabled
      const { value } = await connection.getSignatureStatus(signature, { searchTransactionHistory: true });
      
      // Check for "finalized" status to confirm the transaction
      if (value && value.confirmationStatus === 'finalized') {
        return value; // Exit loop and return confirmation
      }
    } catch (error) {
      console.warn("Polling error, retrying...", error); // Catch transient errors
    }

    await sleep(1000); // Poll every 1000 ms (1 second) to reduce request load
  }

  throw new Error('Transaction confirmation timed out.');
}

const handleDeposit = async () => {
  console.log("Deposit button clicked");

  const provider = window.solana;
  if (!provider || !provider.isPhantom) {
    console.log("Phantom wallet not found");
    setAlertMessage("Phantom wallet not found! Please install Phantom.");
    setAlertVisible(true);
    return;
  }

  try {
    console.log("Starting deposit process...");
    const amount = parseFloat(prompt("Enter the amount of CLOWN tokens to deposit:"));
    if (isNaN(amount) || amount <= 0) {
      console.log("Invalid amount entered:", amount);
      setAlertMessage("Invalid amount entered.");
      setAlertVisible(true);
      return;
    }

    // Connect to the wallet
    const resp = await provider.connect();
    const userPublicKey = resp.publicKey;
    console.log("Wallet connected. Public key:", userPublicKey.toString());

    // Set up connection using HTTP endpoint
    const poolPublicKey = new PublicKey(process.env.REACT_APP_CENTRAL_WALLET);
    const mintAddress = process.env.REACT_APP_MINT_ADDRESS;
    const connection = new Connection(process.env.REACT_APP_SOLANA_RPC_URL, {
      commitment: 'confirmed',
      disableWebsocket: true // Optional flag if supported
    });

    console.log("Using pool wallet:", poolPublicKey.toString());
    console.log("Using mint address:", mintAddress);
    console.log("Using Solana RPC URL:", connection._rpcEndpoint);

    // Retrieve token decimals dynamically
    const decimals = await getTokenDecimals(mintAddress);
    console.log(`Token decimals: ${decimals}`);

    // Fetch user token account (will be created if it doesn’t exist)
    const userTokenAccount = await getAssociatedTokenAddress(new PublicKey(mintAddress), userPublicKey);
    const poolTokenAccount = await getAssociatedTokenAddress(new PublicKey(mintAddress), poolPublicKey);

    console.log("User token account:", userTokenAccount.toString());
    console.log("Pool token account:", poolTokenAccount.toString());

    // Set up transfer instruction
    const transferInstruction = createTransferCheckedInstruction(
      userTokenAccount,
      new PublicKey(mintAddress),
      poolTokenAccount,
      userPublicKey,
      amount * 10 ** decimals,
      decimals
    );

    console.log("Creating transaction...");
    const transaction = new Transaction().add(transferInstruction);
    transaction.feePayer = userPublicKey;

    const { blockhash } = await connection.getLatestBlockhash()
    transaction.recentBlockhash = blockhash;

    console.log("Signing and sending transaction...");
    const { signature } = await provider.signAndSendTransaction(transaction);
    console.log("Transaction signature:", signature);

    // Confirm transaction with HTTP polling
    const confirmation = await confirmTransactionWithHttpPolling(connection, signature);
    console.log("Transaction confirmed with status:", confirmation);

    // Emit deposit event with wallet address and amount
    console.log("Emitting deposit event via socket...");
    socket.emit('deposit', { walletAddress: userPublicKey.toString(), amount });

  } catch (err) {
    console.error("Deposit process failed", err);
    setAlertMessage("Deposit failed. Please try again.");
    setAlertVisible(true);
  }
};

const handleWithdraw = async () => {
  const provider = window.solana;

  if (!provider || !provider.isPhantom) {
    setAlertMessage("Phantom wallet not found! Please install Phantom.");
    setAlertVisible(true);
    return;
  }

  try {
    const amount = parseFloat(prompt("Enter the amount of CLOWN tokens to withdraw:"));
    if (isNaN(amount) || amount <= 0) {
      setAlertMessage("Invalid amount entered.");
      setAlertVisible(true);
      return;
    }

    // Connect to wallet and get user public key
    const resp = await provider.connect();
    const userPublicKey = resp.publicKey.toString();
    console.log("Wallet connected. Public key:", userPublicKey);

    // Emit a withdraw event through WebSocket
    socket.emit('withdraw', { walletAddress: userPublicKey, amount });

    // Listen for withdrawal status updates from the server
    socket.on('withdraw_status', (response) => {
      if (response.success) {
        setAlertMessage(`Successfully withdrew ${amount} CLOWN tokens.`);
        setBalance(response.balance); // Update balance with the new balance from the server
      } else {
        setAlertMessage(response.message || "Withdrawal failed. Please try again.");
      }
      setAlertVisible(true);
    });

  } catch (error) {
    console.error("Error during withdrawal process:", error);
    setAlertMessage("Withdrawal process failed. Please try again.");
    setAlertVisible(true);
  }
};

const disconnectWallet = () => {
  const provider = window.solana;
  
  if (provider && provider.isPhantom) {
    try {
      provider.disconnect();
      
      // Clear wallet-related state and local storage
      localStorage.removeItem('walletAddress');
      setWalletAddress('');
      setBalance(0);
      
      setAlertMessage("Wallet disconnected successfully.");
      setAlertVisible(true);
      
      // Optionally emit an event to the server about wallet disconnection
      socket.emit('disconnect_wallet', { walletAddress });
    } catch (err) {
      console.error("Wallet disconnection failed", err);
      setAlertMessage("Wallet disconnection failed. Please try again.");
      setAlertVisible(true);
    }
  }
};

useEffect(() => {
  socket.on('game_update', (data) => {
    if (data.type === 'balance_update') {
      setBalance(data.newBalance); // Update the balance when the server notifies
      setAlertMessage(data.message); // Show a message, e.g., "You won X CLOWNS!"
      setAlertVisible(true);
    } else if (data.type === 'loser_notification') {
      setAlertMessage(data.message); // Notify the user if they lost
      setAlertVisible(true);
    }
  });

  return () => {
    socket.off('game_update');
  };
}, []);

  const handleCloseAlert = () => {
    setAlertVisible(false); 
  };

  const handleCloseDeposit = () => {
    setDepositVisible(false);
  };

  const handleCloseWithdraw = () => {
    setWithdrawVisible(false);
  };

  return (
    <div className="window p-0 shadow-lg w-[100%] h-[85%] flex flex-col border border-black rounded-m bg-black bg-opacity-85">
      <ChatHeader userCount={userCount} />
      <MessageList messages={messages} username={username} />
      <MessageInput sendMessage={handleSendMessage} sendHonkMessage={handleHonkMessage} />
      <div className="clown-name-section text-white font-bold text-sm shadow flex items-center justify-between p-2 ">
        <span>Clown Name: {username}</span>
        <span>
        {gameDetails 
        ? `${gameDetails.hostUsername}'s roulette: ${gameDetails.playerCount} players, ${gameDetails.totalBet} CLOWNS bets! 🎯💰`
        : 'No games happening 🤡'}        </span>
        <span>Game Commands: /help </span>
      </div>
      <div className="clown-name-section text-white font-bold shadow flex items-center justify-center space-x-10 p-2">
        <button
          className="button text-white"
          onClick={() => setCreateGameModalVisible(true)}>
          Create Game
        </button>
        {createGameModalVisible && (
          <CreateGameModal 
            walletAddress={walletAddress}
            socket={socket}
            setCreateGameModalVisible={setCreateGameModalVisible}
            setAlertMessage={setAlertMessage}
            setAlertVisible={setAlertVisible}
          />
        )}
        <button
          className="button text-white"
          onClick={() => {
            console.log("Join button clicked!");
            socket.emit('join_game', {walletAddress});
          }}>
          Join game
        </button>

        <button 
          className="button text-white"
          onClick={() => {
            const pulls = 1;
            console.log("Pull button clicked!");
            socket.emit('pull_trigger', { 
              pulls,
              walletAddress 
            });
          }}>
          Pull 🔫
        </button>

        <button
          className="button text-white"
          onClick={() => {
            console.log("Pass button clicked!");
            socket.emit('pass_turn', { walletAddress });
          }}>
          Pass
        </button>
      </div>
      <div className="w-full h-[2px] bg-white opacity-20"></div>
      <div className="wallet-actions flex items-center justify-between p-2 bg-transparent">
        <div className="flex items-center">
          <button
            className={`button ${walletAddress ? 'bg-green-500 text-white' : 'text-white'}`}
            onClick={connectWallet}
            disabled={!!walletAddress}
          >
            {walletAddress ? 'Connected!' : 'Connect Wallet'}
          </button>
          {walletAddress && (
            <div className="flex items-center ml-2">
              <div className="balance-display text-white font-bold">Balance: {balance} CLOWNS</div>
              <button
                className="button text-white ml-2"
                onClick={() => {
                  console.log("Deposit button clicked!");
                  handleDeposit();
                }}
              >
                Deposit
              </button>
              <button className="button text-white ml-2" onClick={handleWithdraw}>Withdraw</button>
              <button className="button text-white ml-2" onClick={disconnectWallet}>Disconnect</button>
            </div>
          )}
        </div>
      </div>
      {alertVisible && (
        <Alert 
          message={alertMessage} 
          onClose={handleCloseAlert} 
          style={{ 
            zIndex: 9999,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            maxWidth: '90%'
          }} 
        />
      )}
      {depositVisible && <DepositPrompt onClose={handleCloseDeposit} onSubmit={(amount) => {
          if (amount && !isNaN(amount)) socket.emit('deposit', { walletAddress, amount });
        }}
      />}
      {withdrawVisible && <WithdrawPrompt onClose={handleCloseWithdraw} onSubmit={(amount) => {
          if (amount && !isNaN(amount)) socket.emit('withdraw', { walletAddress, amount });
        }}
      />}
    </div>
  );
}
export default ChatBox;
