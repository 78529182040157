const { Connection, PublicKey } = require('@solana/web3.js');

async function getTokenDecimals(mintAddress) {
  const connection = new Connection(
    process.env.REACT_APP_SOLANA_RPC_URL || "https://mainnet.helius-rpc.com/?api-key=197316c6-7bd2-43b2-aa66-16bf2091e334",
    'confirmed'
  );
  
  try {
    if (!mintAddress || mintAddress.length !== 44) {
      throw new Error("Invalid mint address format");
    }

    const mintPublicKey = new PublicKey(mintAddress);
    const tokenSupply = await connection.getTokenSupply(mintPublicKey);
    return tokenSupply.value.decimals;
  } catch (error) {
    console.error("Error in getTokenDecimals:", error);
    // Return a default decimal (e.g., 9) if there's an issue with the mint address
    return 9;
  }
}

module.exports = { getTokenDecimals };
